<template>
  <div>
    <!-- <h4 class="header-title">List Quotation</h4> -->
    <vue-element-loading
      :active="isLoadingTable"
      spinner="bar-fade-scale"
      color="#FF6700"
    />
    <!-- <div class="row">
      <div class="col-lg-3">
        <router-link v-can="'QUOTATION_CREATE'" :to="{ name: 'Quotation Create' }" class="btn btn-create">
          <i class="mdi mdi-plus-circle mr-1"></i>
          Create New
        </router-link>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-9">
        <div class="float-lg-right float-left">
          <div class="form-inline">
            <label class="mb-2 mr-sm-2">Search :</label>
            <input v-model="filterText" type="text" class="form-control form-control-sm mb-2 "
                   placeholder="Search . . ." @keyup="doFilter"/>
          </div>
        </div>
      </div>


    </div> -->
    <div class="row">
      <div class="col-md-12">
        <span style="font-weight: bold">Data 1</span>
      </div>
    </div>
    <hr style="border-top: 1px solid #e6e6e6" />
    <div class="row">
      <div class="col-md-2">
        <label>Branch</label>
        <multiselect
          id="customer"
          v-model="branchData1"
          placeholder="Choose a Branch"
          :searchable="true"
          :allow-empty="true"
          :clearable="true"
          :loading="isLoading"
          :multiple="true"
          :close-on-select="false"
          :options="listUserBranch"
          track-by="id_branch"
          :custom-label="(opt) => opt.branch.nama_branch"
          required
          @input="getMarketingData1"
          @close="getMarketingData1"
          @select="getMarketingData1"
          select-label=""
          selectedLabel=""
          deselectLabel=""
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__small">{{
                props.option.branch.nama_branch
              }}</span>
            </div>
          </template>
        </multiselect>
        <!-- <select
            v-model="branchData1"
            v-can="'CRM_ADMIN_ENABLE'"
            class="form-control"
            name="created_by"
            data-live-search="true"
            placeholder="Branch"
            @change="getMarketingData1"
          >
            <option
              v-for="(item, key) in listUserBranch"
              :key="key"
              :value="item.branch.id"
            >
              {{ item.branch.nama_branch }}
            </option>
          </select> -->
      </div>
      <div class="col-md-1">
        <label>Status</label>
        <select
          v-model="statusData1"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Status"
        >
          <option value="All" selected>All</option>
          <option value="active">Active</option>
          <option value="suspend">Suspend</option>
        </select>
      </div>
      <div class="col-md-2">
        <label>Marketing</label>
        <multiselect
          id="customer"
          v-model="marketingData1"
          placeholder="Choose a Marketing"
          :searchable="true"
          :allow-empty="true"
          :clearable="true"
          :loading="isLoading"
          :multiple="true"
          :close-on-select="false"
          :options="listMarketingByBranchData1"
          track-by="id_user"
          label="name_user"
          required
          select-label=""
          selectedLabel=""
          deselectLabel=""
        >
          <!-- <template slot="option" slot-scope="props">
              <div class="option__desc"><span class="option__small">{{ props.option.branch.nama_branch }}</span></div>
            </template> -->
        </multiselect>

        <!-- <select
            v-model="marketingData1"
            v-can="'CRM_ADMIN_ENABLE'"
            class="form-control"
            name="created_by"
            data-live-search="true"
            placeholder="Choose Marketing"
            @change="fecthData"
          >
            <option value="All Marketing" selected>All Marketing</option>
            <option
              v-for="(value, key) in listMarketingByBranchData1"
              :key="key"
              :value="value.id_user"
            >
              {{ value.name_user }}
            </option>
          </select> -->
      </div>
      <div class="col-md-1">
        <label>Range</label>
        <select
          v-model="rangeData1"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Range"
          @change="fecthData"
        >
          <option value="customData1" selected>Custom</option>
          <option value="monthlyData1">Monthly</option>
          <option value="yearlyData1">Yearly</option>
        </select>
      </div>
      <div
        v-if="rangeData1 === 'customData1'"
        class="col-md-2 justify-content-end"
      >
        <label>Start Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="startDateData1"
          :format="momentFormatStartDateData1"
          type="date"
          lang="en"
          :name="'startDate'"
          :placeholder="'Start Date'"
        >
        </date-picker>
      </div>
      <div
        v-if="rangeData1 === 'customData1'"
        class="col-md-2 justify-content-end"
      >
        <label>End Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="endDateData1"
          :format="momentFormatEndDateData1"
          type="date"
          lang="en"
          :name="'endDate'"
          :placeholder="'End Date'"
          @input="fecthData"
        >
        </date-picker>
      </div>
      <div
        v-if="rangeData1 === 'monthlyData1'"
        class="col-md-2 justify-content-end"
      >
        <label>Month</label>
        <select
          v-model="monthData1"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Range"
          @change="fecthData"
        >
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
      <div
        v-if="rangeData1 === 'monthlyData1' || rangeData1 === 'yearlyData1'"
        class="col-md-2 justify-content-end"
      >
        <label>Year</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="yearData1"
          type="year"
          :format="formatYearData1"
          :name="'yearData1'"
          :placeholder="'Year'"
          @input="fecthData"
        >
        </date-picker>
      </div>
      <div class="col-md-2">
        <label>Top Customer</label>
        <div class="input-group">
          <select
            v-if="isAutoTopCustData1"
            v-model="topCustomerData1"
            class="form-control"
            name="created_by"
            data-live-search="true"
            placeholder="Range"
            @change="fecthData"
          >
            <option value="15" selected>15</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
          <b-form-input
            v-if="isManualTopCustData1"
            v-model="topCustomerData1"
          ></b-form-input>
          <div
            class="input-group-append"
            style="background: #51c1ad; border-radius: 0px 3px 3px 0px"
          >
            <button class="btn" type="button" @click="changeTypeTopCustData1">
              <div v-if="isAutoTopCustData1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5471_8985)">
                    <path
                      d="M15.6955 2.78553L13.2098 0.299919C12.8168 -0.0931369 12.1315 -0.0931369 11.7378 0.299919L11.1367 0.90097L15.0951 4.85865L15.6955 4.25829C15.8923 4.06141 16.0002 3.79983 16.0002 3.52158C16.0001 3.2433 15.8923 2.98171 15.6955 2.78553Z"
                      fill="white"
                    />
                    <path
                      d="M0.0135096 15.5518C-0.021271 15.6729 0.0121346 15.8037 0.101852 15.8934C0.167945 15.9595 0.256974 15.995 0.348129 15.995C0.379441 15.995 0.41144 15.9908 0.44344 15.9818L5.11627 14.6468L1.34921 10.879L0.0135096 15.5518Z"
                      fill="white"
                    />
                    <path
                      d="M1.74549 10.2919L5.70312 14.2495L14.6027 5.34989L10.6451 1.39225L1.74549 10.2919Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5471_8985">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div v-if="isManualTopCustData1">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.64999 5.00031L9.74362 1.90668C10.0854 1.56487 10.0854 1.0107 9.74362 0.669421L9.3312 0.257001C8.98928 -0.0849194 8.43511 -0.0849194 8.09383 0.257001L5.00031 3.35052L1.90668 0.25636C1.56487 -0.0854535 1.0107 -0.0854535 0.669421 0.25636L0.25636 0.66878C-0.0854535 1.0107 -0.0854535 1.56487 0.25636 1.90615L3.35052 5.00031L0.257001 8.09383C-0.0849194 8.43575 -0.0849194 8.98992 0.257001 9.3312L0.669421 9.74362C1.01124 10.0854 1.5654 10.0854 1.90668 9.74362L5.00031 6.64999L8.09383 9.74362C8.43575 10.0854 8.98992 10.0854 9.3312 9.74362L9.74362 9.3312C10.0854 8.98928 10.0854 8.43511 9.74362 8.09383L6.64999 5.00031Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr v-if="compareData" style="border-top: 1px solid #e6e6e6" />
    <!-- Data 2 -->
    <div v-if="compareData" class="row">
      <div class="col-md-12">
        <span style="font-weight: bold">Data 2</span>
      </div>
    </div>
    <hr style="border-top: 1px solid #e6e6e6" />
    <div v-if="compareData" class="row">
      <div class="col-md-2">
        <label>Branch</label>
        <multiselect
          id="customer"
          v-model="branchData2"
          placeholder="Choose a Branch"
          :searchable="true"
          :allow-empty="true"
          :clearable="true"
          :loading="isLoadingData2"
          :multiple="true"
          :close-on-select="false"
          :options="listUserBranch"
          track-by="id_branch"
          :custom-label="(opt) => opt.branch.nama_branch"
          required
          @input="getMarketingData2"
          @close="getMarketingData2"
          @select="getMarketingData2"
          select-label=""
          selectedLabel=""
          deselectLabel=""
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__small">{{
                props.option.branch.nama_branch
              }}</span>
            </div>
          </template>
        </multiselect>
        <!-- <select
            v-model="branchData2"
            v-can="'CRM_ADMIN_ENABLE'"
            class="form-control"
            name="created_by"
            data-live-search="true"
            placeholder="Branch"
            @change="getMarketingData2"
          >
            <option
              v-for="(item, key) in listUserBranch"
              :key="key"
              :value="item.branch.id"
            >
              {{ item.branch.nama_branch }}
            </option>
          </select> -->
      </div>
      <div class="col-md-1">
        <label>Status</label>
        <select
          v-model="statusData2"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Status"
        >
          <option value="All" selected>All</option>
          <option value="active">Active</option>
          <option value="suspend">Suspend</option>
        </select>
      </div>
      <div class="col-md-2">
        <label>Marketing</label>
        <multiselect
          id="customer"
          v-model="marketingData2"
          placeholder="Choose a Marketing"
          :searchable="true"
          :allow-empty="true"
          :clearable="true"
          :loading="isLoading"
          :multiple="true"
          :close-on-select="false"
          :options="listMarketingByBranchData2"
          track-by="id_user"
          label="name_user"
          required
          select-label=""
          selectedLabel=""
          deselectLabel=""
        >
          <!-- <template slot="option" slot-scope="props">
              <div class="option__desc"><span class="option__small">{{ props.option.branch.nama_branch }}</span></div>
            </template> -->
        </multiselect>
        <!-- <select
            v-model="marketingData2"
            v-can="'CRM_ADMIN_ENABLE'"
            class="form-control"
            name="created_by"
            data-live-search="true"
            placeholder="Choose Marketing"
            @change="fecthData"
          >
            <option value="All Marketing" selected>All Marketing</option>
            <option
              v-for="(value, key) in listMarketingByBranchData2"
              :key="key"
              :value="value.id_user"
            >
              {{ value.name_user }}
            </option>
          </select> -->
      </div>
      <div class="col-md-1">
        <label>Range</label>
        <select
          v-model="rangeData2"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Range"
          @change="fecthData"
        >
          <option value="customData2" selected>Custom</option>
          <option value="monthlyData2">Monthly</option>
          <option value="yearlyData2">Yearly</option>
        </select>
      </div>
      <div
        v-if="rangeData2 === 'customData2'"
        class="col-md-2 justify-content-end"
      >
        <label>Start Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="startDateData2"
          :format="momentFormatStartDateData2"
          type="date"
          lang="en"
          :name="'startDate'"
          :placeholder="'Start Date'"
        >
        </date-picker>
      </div>
      <div
        v-if="rangeData2 === 'customData2'"
        class="col-md-2 justify-content-end"
      >
        <label>End Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="endDateData2"
          :format="momentFormatEndDateData2"
          type="date"
          lang="en"
          :name="'endDate'"
          :placeholder="'End Date'"
          @input="fecthData"
        >
        </date-picker>
      </div>
      <div
        v-if="rangeData2 === 'monthlyData2'"
        class="col-md-2 justify-content-end"
      >
        <label>Month</label>
        <select
          v-model="monthData2"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Range"
          @change="fecthData"
        >
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
      <div
        v-if="rangeData2 === 'monthlyData2' || rangeData2 === 'yearlyData2'"
        class="col-md-2 justify-content-end"
      >
        <label>Year</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="yearData2"
          type="year"
          :format="formatYearData2"
          :name="'yearData2'"
          :placeholder="'Year'"
          @input="fecthData"
        >
        </date-picker>
      </div>
      <div class="col-md-2">
        <label>Top Customer</label>
        <div class="input-group mb-3">
          <select
            v-if="isAutoTopCustData2"
            v-model="topCustomerData2"
            class="form-control"
            name="created_by"
            data-live-search="true"
            placeholder="Range"
            @change="fecthData"
          >
            <option value="15" selected>15</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
          <b-form-input
            v-if="isManualTopCustData2"
            v-model="topCustomerData2"
          ></b-form-input>
          <div
            class="input-group-append"
            style="background: #51c1ad; border-radius: 0px 3px 3px 0px"
          >
            <button class="btn" type="button" @click="changeTypeTopCustData2">
              <div v-if="isAutoTopCustData2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5471_8985)">
                    <path
                      d="M15.6955 2.78553L13.2098 0.299919C12.8168 -0.0931369 12.1315 -0.0931369 11.7378 0.299919L11.1367 0.90097L15.0951 4.85865L15.6955 4.25829C15.8923 4.06141 16.0002 3.79983 16.0002 3.52158C16.0001 3.2433 15.8923 2.98171 15.6955 2.78553Z"
                      fill="white"
                    />
                    <path
                      d="M0.0135096 15.5518C-0.021271 15.6729 0.0121346 15.8037 0.101852 15.8934C0.167945 15.9595 0.256974 15.995 0.348129 15.995C0.379441 15.995 0.41144 15.9908 0.44344 15.9818L5.11627 14.6468L1.34921 10.879L0.0135096 15.5518Z"
                      fill="white"
                    />
                    <path
                      d="M1.74549 10.2919L5.70312 14.2495L14.6027 5.34989L10.6451 1.39225L1.74549 10.2919Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5471_8985">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div v-if="isManualTopCustData2">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.64999 5.00031L9.74362 1.90668C10.0854 1.56487 10.0854 1.0107 9.74362 0.669421L9.3312 0.257001C8.98928 -0.0849194 8.43511 -0.0849194 8.09383 0.257001L5.00031 3.35052L1.90668 0.25636C1.56487 -0.0854535 1.0107 -0.0854535 0.669421 0.25636L0.25636 0.66878C-0.0854535 1.0107 -0.0854535 1.56487 0.25636 1.90615L3.35052 5.00031L0.257001 8.09383C-0.0849194 8.43575 -0.0849194 8.98992 0.257001 9.3312L0.669421 9.74362C1.01124 10.0854 1.5654 10.0854 1.90668 9.74362L5.00031 6.64999L8.09383 9.74362C8.43575 10.0854 8.98992 10.0854 9.3312 9.74362L9.74362 9.3312C10.0854 8.98928 10.0854 8.43511 9.74362 8.09383L6.64999 5.00031Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr v-if="compareData" style="border-top: 1px solid #e6e6e6" />
    <div class="row">
      <div class="col-md-6" style="align-content: center; display: flex">
        <!-- <div class="form-check form-check-inline">
          <input id="inlineCheckbox3" class="form-check-input" type="checkbox" value="option3">
          <label class="form-check-label" for="inlineCheckbox3">Compare Data</label>
        </div> -->
        <b-form-checkbox
          id="checkbox-1"
          v-model="compareData"
          name="checkbox-1"
          :checked="true"
          @change="CheckCompareData"
        >
          Compare Data
        </b-form-checkbox>
      </div>
      <div class="col-md-6" style="display: flex; justify-content: end">
        <div>
          <b-dropdown
            id="dropdown-1"
            right
            variant="success"
            class="m-md-2 btn-create"
            no-caret
          >
            <template v-slot:button-content>
              Filter
              <i class="fa fa-caret-down ml-2"></i>
            </template>
            <b-dropdown-item @click="doFilter"
              ><i class="fa fa-edit mr-2"></i>Filter Data</b-dropdown-item
            >
            <b-dropdown-item @click="exportData"
              ><i class="fa fa-edit mr-2"></i>Export PDF</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
    <!-- Table -->
    <div class="table-responsive mt-3">
      <span>Data:</span>
      <span style="font-weight: bold">{{ formatDate('Data1') }}</span>
      <vuetable
        ref="vuetableData1"
        :per-page="perPage"
        :fields="fields"
        :api-url="apiUrl"
        :api-mode="true"
        :http-options="httpOptions"
        :show-sort-icons="false"
        :shortable="false"
        :append-params="moreParamsData1"
        pagination-path
        class="table table-centered table-striped mt-2"
        data-path="data"
        @vuetable:loading="loadingTableData1"
        @vuetable:load-success="loadTableSuccessData1"
        @vuetable:load-error="loadTableFailedData1"
        @vuetable:pagination-data="onPaginationData1"
        @vuetable:sort="false"
      >
        <template slot="actions" slot-scope="props">
          <div class="table-button-container">
            <a
              target="_blank"
              class="text-white btn btn-sm btn-secondary mr-1"
              :style="`minWidth: 80px;background: ${
                props.rowData.status === 0 ? '#F6A11C' : '#BFBFBF'
              };borderColor: ${
                props.rowData.status === 0 ? '#F6A11C' : '#BFBFBF'
              };`"
            >
              {{ props.rowData.status === 0 ? 'Active' : 'Suspended' }}
            </a>
            <!-- <a v-can="'QUOTATION_UPDATE'" href="javascript:void(0);" class="action-icon text-white btn btn-sm mr-1"
               style="background: #51C1AD;"
               @click="onAction('edit-item', props.rowData, props.rowIndex)">
              <i class="mdi mdi-square-edit-outline"></i>
            </a>
            <a v-can="'QUOTATION_DELETE'" href="javascript:void(0);" class="action-icon text-white btn btn-sm"
               style="background: #EA4C40;"
               @click="onAction('delete-item', props.rowData, props.rowIndex)">
              <i class="mdi mdi-delete"></i>
            </a> -->
          </div>
        </template>
        <template slot="profit" slot-scope="props">
          Rp. {{ formatPrice(props.rowData.profit) }}
        </template>
      </vuetable>
    </div>
    <!-- end table -->

    <!-- pagination -->
    <!-- <div class="row">
      <div class="col-md-5">
        <div class="row no-gutters">
          <div class="col-lg-3">
            <div class="form-group float-lg-left ">
              <select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
                <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                  {{opt}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">

            <vuetable-pagination-info ref="paginationInfo"
                                      class="justify-content-center justify-content-lg-start text-center text-lg-left">
            </vuetable-pagination-info>
          </div>
        </div>


      </div>
      <div class="col-sm-12 col-md-7">
        <vuetable-pagination ref="pagination" :css="css.pagination"
                             class="pagination pagination-rounded justify-content-center justify-content-lg-end"
                             @vuetable-pagination:change-page="onChangePage">
        </vuetable-pagination>
      </div>
    </div> -->
    <!-- end pagination -->

    <!-- Table -->
    <div v-if="compareData" class="table-responsive mt-3">
      <span>Data:</span>
      <span style="font-weight: bold">{{ formatDate('Data2') }}</span>
      <vuetable
        ref="vuetableData2"
        :per-page="perPage"
        :fields="fields"
        :api-url="apiUrl"
        :api-mode="true"
        :http-options="httpOptions"
        :show-sort-icons="true"
        :append-params="moreParamsData2"
        class="table table-centered table-striped mt-2"
        data-path="data"
        @vuetable:loading="loadingTableData2"
        @vuetable:load-success="loadTableSuccessData2"
        @vuetable:load-error="loadTableFailedData2"
      >
        <template slot="actions" slot-scope="props">
          <div class="table-button-container">
            <a
              target="_blank"
              class="text-white btn btn-sm btn-secondary mr-1"
              :style="`minWidth: 80px;background: ${
                props.rowData.status === 0 ? '#F6A11C' : '#BFBFBF'
              };borderColor: ${
                props.rowData.status === 0 ? '#F6A11C' : '#BFBFBF'
              };`"
            >
              {{ props.rowData.status === 0 ? 'Active' : 'Suspended' }}
            </a>
            <!-- <a v-can="'QUOTATION_UPDATE'" href="javascript:void(0);" class="action-icon text-white btn btn-sm mr-1"
               style="background: #51C1AD;"
               @click="onAction('edit-item', props.rowData, props.rowIndex)">
              <i class="mdi mdi-square-edit-outline"></i>
            </a>
            <a v-can="'QUOTATION_DELETE'" href="javascript:void(0);" class="action-icon text-white btn btn-sm"
               style="background: #EA4C40;"
               @click="onAction('delete-item', props.rowData, props.rowIndex)">
              <i class="mdi mdi-delete"></i>
            </a> -->
          </div>
        </template>
        <template slot="profit" slot-scope="props">
          Rp. {{ formatPrice(props.rowData.profit) }}
        </template>
      </vuetable>
    </div>
    <!-- end table -->

    <!-- pagination -->
    <!-- <div class="row" v-if="compareData">
      <div class="col-md-5">
        <div class="row no-gutters">
          <div class="col-lg-3">
            <div class="form-group float-lg-left ">
              <select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
                <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                  {{opt}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">

            <vuetable-pagination-info ref="paginationInfo"
                                      class="justify-content-center justify-content-lg-start text-center text-lg-left">
            </vuetable-pagination-info>
          </div>
        </div>


      </div>
      <div class="col-sm-12 col-md-7">
        <vuetable-pagination ref="pagination" :css="css.pagination"
                             class="pagination pagination-rounded justify-content-center justify-content-lg-end"
                             @vuetable-pagination:change-page="onChangePage">
        </vuetable-pagination>
      </div>
    </div> -->
    <!-- end pagination -->
  </div>
</template>
<style>
[type='checkbox']:checked + label:before {
  background-color: #51c1ad !important;
  border-color: #51c1ad !important;
}
</style>
<script>
import Swal from 'sweetalert2'
import Vuetable from 'vuetable-2/src/components/Vuetable'
// import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
// import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'
// import 'vue2-datepicker/locale/en'
import moment from 'moment'
import { authMethods } from '@state/helpers'
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
var url = '/quotation'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  // const element = array[i];
  // if (! JSON.parse(localStorage.getItem('userPermissions'))
  //       .includes(binding.expression
  //         .replace(/'/g, "")
  //           .replace(/"/g, ""))) {
  //      url = '/quotation';
  // }else{
  //   url = '/quotation?admin';
  // }
  // console.log(JSON.parse(localStorage.getItem('userPermissions'))[i])
  // if (
  //   JSON.parse(localStorage.getItem('userPermissions'))[i] ===
  //   'CRM_ADMIN_ENABLE'
  // ) {
    url = '/best-customer'
  // }
}
console.log(url, 'url')

export default {
  components: {
    Vuetable,
    // VuetablePagination,
    // VuetablePaginationInfo,
    DatePicker,
    Multiselect,
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moreParamsData1: {},
      moreParamsData2: {},
      filterText: '',
      deleteData: {},
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + url,
      urlPdf: '#',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'Best Customer',
          href: '/best-customer',
        },
        {
          text: 'List',
          active: true,
        },
      ],
      lengthMenu: [5, 10, 25, 26, 27, 28, 29, 50, 100],
      perPage: 5,
      fields: [
        {
          name: 'no',
          title: 'No',
        },
        {
          name: 'customer',
          title: 'Customer',
        },
        {
          name: 'branch',
          title: 'Branch',
        },
        {
          name: 'marketing',
          title: 'Marketing',
        },
        {
          name: 'FCL',
          title: 'FCL',
        },
        {
          name: 'LCL',
          title: 'LCL',
        },
        {
          name: 'RORO',
          title: 'RORO',
        },
        {
          name: 'breakbulk',
          title: 'Breakbulk',
        },
        {
          name: 'profit',
          title: 'Profit',
        },
        {
          name: 'actions',
          title: 'Status',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
      css: {
        pagination: {
          activeClass: 'btn-primary text-white',
          pageClass: 'btn btn-sm ',
          linkClass: 'btn btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      momentFormatStartDateData1: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.startDateData1).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatStartDateData2: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.startDateData2).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatEndDateData1: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.endDateData1).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatEndDateData2: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.endDateData2).format('YYYY/MM/DD') : ''
        },
      },
      year: null,
      createdBy: null,
      statusData1: 'All',
      startDateData1: null,
      endDateData1: null,
      branchData1: [],
      marketingData1: [],
      rangeData1: 'customData1',
      topCustomerData1: '15',
      statusData2: 'All',
      startDateData2: null,
      endDateData2: null,
      branchData2: [],
      marketingData2: [],
      rangeData2: 'customData2',
      topCustomerData2: '15',
      compareData: false,
      isManualTopCustData1: false,
      isAutoTopCustData1: true,
      isManualTopCustData2: false,
      isAutoTopCustData2: true,
      monthData1: '01',
      monthData2: '01',
      // formatMonthData1: {
      //  // this.dataPayload.year
      //  stringify: (date) => {
      //    return date ? moment(this.monthData1).format('MM') : ''
      //  },
      // },
      // formatMonthData2: {
      //  // this.dataPayload.year
      //  stringify: (date) => {
      //    return date ? moment(this.monthData2).format('MM') : ''
      //  },
      // },
      yearData1: null,
      yearData2: null,
      formatYearData1: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.yearData1).format('YYYY') : ''
        },
      },
      formatYearData2: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.yearData2).format('YYYY') : ''
        },
      },
      isLoading: false,
      isLoadingData2: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  computed: {
    ...mapState('user', [
      'listUser',
      'dataUser',
      'listMarketingByBranchData1',
      'listMarketingByBranchData2',
      'listMarketingByPermissions'
    ]),
    ...mapState('userBranch', ['listUserBranch']),
  },
  mounted() {
    this.getUser()
    // this.getMarketingByBranchData1()
    // this.getMarketingByBranchData2()
    this.getMarketingByPermission()
    this.get_branch()
  },
  methods: {
    ...authMethods,
    errorAlertValidation: function (message = 'Terjadi Kesalahan') {
      this.$swal({
        title: message,
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
    },
    loginByToken() {
      console.log('login by token')
    },

    ...mapActions('best-customer', ['deleteQuotation', 'generatePdf']),
    ...mapActions('user', [
      'getListUser',
      'getListMarketingByBranchData1',
      'getListMarketingByBranchData2',
      'getListMarketingByPermissions'
    ]),
    ...mapActions('userBranch', ['getListUserBranch']),
    doFilter() {
      this.onFilterSet()
      // console.log(this.branchData1, 'THIS BRANCH DATA 1');
    },
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData1(paginationData) {
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onPaginationData2(paginationData) {
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet() {
      let tempStartDateData1 = ''
      let tempEndDateData1 = ''

      let tempStartDateData2 = ''
      let tempEndDateData2 = ''

      // console.log(this.monthData1.toString())
      // console.log(new Date(this.yearData2.toString()).getFullYear())

      if (this.rangeData1 === 'customData1') {
        tempStartDateData1 =
          new Date(this.startDateData1).getFullYear() +
          '/' +
          (new Date(this.startDateData1).getMonth() + 1) +
          '/' +
          new Date(this.startDateData1).getDate()
        tempEndDateData1 =
          new Date(this.endDateData1).getFullYear() +
          '/' +
          (new Date(this.endDateData1).getMonth() + 1) +
          '/' +
          new Date(this.endDateData1).getDate()
      } else if (this.rangeData1 === 'monthlyData1') {
        if (this.yearData1 === null) {
          tempStartDateData1 = null
          tempEndDateData1 = null
        } else {
          tempStartDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            parseInt(this.monthData1.toString()) - 1,
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            parseInt(this.monthData1.toString()),
            1
          )
            .toISOString()
            .split('T')[0]

          console.log(tempStartDateData1, 'THIS DATAA1')
          console.log(tempEndDateData1, 'THIS DATAA2')
          console.log(this.monthData1.toString(), 'THIS DATAA3')
        }
      } else if (this.rangeData1 === 'yearlyData1') {
        if (this.yearData1 === null) {
          tempStartDateData1 = null
          tempEndDateData1 = null
        } else {
          tempStartDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            0,
            2
          )
            .toISOString()
            .split('T')[0]

          tempEndDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear() + 1,
            0,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      }

      if (this.rangeData2 === 'customData2') {
        // tempStartDateData2 = this.startDateData2
        // tempEndDateData2 = this.endDateData2
        tempStartDateData2 =
          new Date(this.startDateData2).getFullYear() +
          '/' +
          (new Date(this.startDateData2).getMonth() + 1) +
          '/' +
          new Date(this.startDateData2).getDate()
        tempEndDateData2 =
          new Date(this.endDateData2).getFullYear() +
          '/' +
          (new Date(this.endDateData2).getMonth() + 1) +
          '/' +
          new Date(this.endDateData2).getDate()
      } else if (this.rangeData2 === 'monthlyData2') {
        if (this.yearData2 === null) {
          tempStartDateData2 = null
          tempEndDateData2 = null
        } else {
          tempStartDateData2 = new Date(
            new Date(this.yearData2.toString()).getFullYear(),
            parseInt(this.monthData2.toString()) - 1,
            1
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData2 = new Date(
            new Date(this.yearData2.toString()).getFullYear(),
            parseInt(this.monthData2.toString()),
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData2 === 'yearlyData2') {
        if (this.yearData2 === null) {
          tempStartDateData2 = null
          tempEndDateData2 = null
        } else {
          tempStartDateData1 = new Date(
            new Date(this.yearData2.toString()).getFullYear(),
            0,
            2
          )
            .toISOString()
            .split('T')[0]

          tempEndDateData1 = new Date(
            new Date(this.yearData2.toString()).getFullYear() + 1,
            0,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      }

      let strBranch1 = []
      this.branchData1.forEach((data, idx) => {
        strBranch1.push(data.id_branch)
        // if(idx === 0) {
        //  strBranch1 += "&id_branch[]=" + data.id_branch
        // } else {
        //  strBranch1 += "&id_branch[]=" + data.id_branch
        // }
      })

      let strMarketing1 = []
      this.marketingData1.forEach((data, idx) => {
        strMarketing1.push(data.id_user)
        // if(idx === 0) {
        //  strMarketing1 += "&id_marketing[]=" + data.id_user
        // } else {
        //  strMarketing1 += "&id_marketing[]=" + data.id_user
        // }
      })

      this.moreParamsData1 = {
        id_branch: strBranch1,
        status: this.statusData1,
        id_marketing: strMarketing1,
        startDate: tempStartDateData1,
        endDate: tempEndDateData1,
        topCustomer: this.topCustomerData1,
      }

      let strBranch2 = []
      this.branchData2.forEach((data, idx) => {
        strBranch2.push(data.id_branch)
        // if(idx === 0) {
        //  strBranch2 += "&id_branch[]=" + data.id_branch
        // } else {
        //  strBranch2 += "&id_branch[]=" + data.id_branch
        // }
      })

      let strMarketing2 = []
      this.marketingData2.forEach((data, idx) => {
        strMarketing2.push(data.id_user)
        // if(idx === 0) {
        //  strMarketing2 += "&id_marketing[]=" + data.id_user
        // } else {
        //  strMarketing2 += "&id_marketing[]=" + data.id_user
        // }
      })

      this.moreParamsData2 = {
        id_branch: strBranch2,
        status: this.statusData2,
        id_marketing: strMarketing2,
        startDate: tempStartDateData2,
        endDate: tempEndDateData2,
        topCustomer: this.topCustomerData2,
      }
      // console.log(this.moreParamsData1, 'THIS FILTER DATA 1')
      // console.log(this.moreParamsData2, 'THIS FILTER DATA 2')
      if (this.branchData1.length === 0) {
        this.errorAlertValidation('Branch Data 1 is required!')
      } else if (this.statusData1 === '') {
        this.errorAlertValidation('Status Data 1 is required!')
      } else if (this.rangeData1 === '') {
        this.errorAlertValidation('Range Data 1 is required!')
      } else if (this.rangeData1 !== '') {
        if (this.rangeData1 === 'customData1') {
          if (this.startDateData1 === '' || this.startDateData1 == null) {
            this.errorAlertValidation('Start Date Data 1 is required!')
          } else if (this.endDateData2 === '' || this.endDateData1 == null) {
            this.errorAlertValidation('End Date Data 1 is required!')
          } else {
            if (this.compareData) {
              if (this.branchData2.length === 0) {
                this.errorAlertValidation('Branch Data 2 is required!')
              } else if (this.statusData2 === '') {
                this.errorAlertValidation('Status Data 2 is required!')
              } else if (this.rangeData2 === '') {
                this.errorAlertValidation('Range Data 2 is required!')
              } else if (this.rangeData2 !== '') {
                if (this.rangeData2 === 'customData2') {
                  if (
                    this.startDateData2 === '' ||
                    this.startDateData2 == null
                  ) {
                    this.errorAlertValidation('Start Date Data 2 is required!')
                  } else if (
                    this.endDateData2 === '' ||
                    this.endDateData2 == null
                  ) {
                    this.errorAlertValidation('End Date Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                } else if (this.rangeData2 === 'monthlyData2') {
                  if (this.monthData2 === '' || this.monthData2 == null) {
                    this.errorAlertValidation('Month Data 2 is required!')
                  } else if (this.yearData2 === '' || this.yearData2 == null) {
                    this.errorAlertValidation('Year Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                } else if (this.rangeData2 === 'yearlyData2') {
                  if (this.yearData2 === '' || this.yearData2 == null) {
                    this.errorAlertValidation('Year Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                }
              }
            } else {
              this.$nextTick(() => this.$refs.vuetableData1.refresh())
            }
          }
        } else if (this.rangeData1 === 'monthlyData1') {
          if (this.monthData1 === '' || this.monthData1 == null) {
            this.errorAlertValidation('Month Data 1 is required!')
          } else if (this.yearData1 === '' || this.yearData1 == null) {
            this.errorAlertValidation('Year Data 1 is required!')
          } else {
            if (this.compareData) {
              if (this.branchData2.length === 0) {
                this.errorAlertValidation('Branch Data 2 is required!')
              } else if (this.statusData2 === '') {
                this.errorAlertValidation('Status Data 2 is required!')
              } else if (this.rangeData2 === '') {
                this.errorAlertValidation('Range Data 2 is required!')
              } else if (this.rangeData2 !== '') {
                if (this.rangeData2 === 'customData2') {
                  if (
                    this.startDateData2 === '' ||
                    this.startDateData2 == null
                  ) {
                    this.errorAlertValidation('Start Date Data 2 is required!')
                  } else if (
                    this.endDateData2 === '' ||
                    this.endDateData2 == null
                  ) {
                    this.errorAlertValidation('End Date Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                } else if (this.rangeData2 === 'monthlyData2') {
                  if (this.monthData2 === '' || this.monthData2 == null) {
                    this.errorAlertValidation('Month Data 2 is required!')
                  } else if (this.yearData2 === '' || this.yearData2 == null) {
                    this.errorAlertValidation('Year Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                } else if (this.rangeData2 === 'yearlyData2') {
                  if (this.yearData2 === '' || this.yearData2 == null) {
                    this.errorAlertValidation('Year Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                }
              }
            } else {
              this.$nextTick(() => this.$refs.vuetableData1.refresh())
            }
          }
        } else if (this.rangeData1 === 'yearlyData1') {
          if (this.yearData1 === '' || this.yearData1 == null) {
            this.errorAlertValidation('Year Data 1 is required!')
          } else {
            if (this.compareData) {
              if (this.branchData2.length === 0) {
                this.errorAlertValidation('Branch Data 2 is required!')
              } else if (this.statusData2 === '') {
                this.errorAlertValidation('Status Data 2 is required!')
              } else if (this.rangeData2 === '') {
                this.errorAlertValidation('Range Data 2 is required!')
              } else if (this.rangeData2 !== '') {
                if (this.rangeData2 === 'customData2') {
                  if (
                    this.startDateData2 === '' ||
                    this.startDateData2 == null
                  ) {
                    this.errorAlertValidation('Start Date Data 2 is required!')
                  } else if (
                    this.endDateData2 === '' ||
                    this.endDateData2 == null
                  ) {
                    this.errorAlertValidation('End Date Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                } else if (this.rangeData2 === 'monthlyData2') {
                  if (this.monthData2 === '' || this.monthData2 == null) {
                    this.errorAlertValidation('Month Data 2 is required!')
                  } else if (this.yearData2 === '' || this.yearData2 == null) {
                    this.errorAlertValidation('Year Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                } else if (this.rangeData2 === 'yearlyData2') {
                  if (this.yearData2 === '' || this.yearData2 == null) {
                    this.errorAlertValidation('Year Data 2 is required!')
                  } else {
                    this.$nextTick(() => this.$refs.vuetableData1.refresh())
                    this.$nextTick(() => this.$refs.vuetableData2.refresh())
                  }
                }
              }
            } else {
              this.$nextTick(() => this.$refs.vuetableData1.refresh())
            }
          }
        }
      }
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTableData1() {
      this.isLoadingTable = true
    },

    loadingTableData2() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccessData1() {
      this.isLoadingTable = false
    },

    loadTableSuccessData2() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailedData1(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },
    loadTableFailedData2(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },
    changeTypeTopCustData1() {
      this.isManualTopCustData1 = !this.isManualTopCustData1
      this.isAutoTopCustData1 = !this.isAutoTopCustData1
      if (this.isAutoTopCustData1) {
        this.topCustomerData1 = 15
      }
    },
    changeTypeTopCustData2() {
      this.isManualTopCustData2 = !this.isManualTopCustData2
      this.isAutoTopCustData2 = !this.isAutoTopCustData2
      if (this.isAutoTopCustData2) {
        this.topCustomerData2 = 15
      }
    },
    onAction(action, rowData, rowIndex) {
      if (action === 'edit-item') {
        this.$router.push({
          name: 'Quotation Edit',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'detail-item') {
        this.$router.push({
          name: 'Quotation Detail',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'view-item') {
        this.$router.push({
          name: 'Quotation View',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'delete-item') {
        this.showConfirmDelete(rowData.id)
      } else if (action === 'view-pdf') {
        let config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        }
        axios({
          url: this.$store.state.rootApi + '/quotation' + '/pdf/' + rowData.id,
          config,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fURL = document.createElement('a')

          fURL.href = fileURL
          fURL.setAttribute('download', 'invoice ' + rowData.id + '.pdf')
          document.body.appendChild(fURL)

          fURL.click()
        })
      }
    },

    showConfirmDelete(id) {
      this.deleteData.id = id
      let self = this
      // this.$swal({
      //  title: 'Are you sure?',
      //  text: 'You will not be able to recover this!',
      //  type: 'warning',
      //  showCancelButton: true,
      //  confirmButtonText: 'Yes, delete it!',
      //  cancelButtonText: 'No, keep it',
      // }).then((result) => {
      //  if (result.value) {
      //    this.$swal('Deleted!', 'Your data has been deleted.', 'error')
      //    self.onDelete()
      //  } else if (result.dismiss) {
      //    this.$swal('Cancelled', 'Your data is safe :)', 'error')
      //  }
      // })
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          self.onDelete()
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        }
      })
    },
    /**
     * Delete Brand
     *
     * @return  {[type]}  [return description]
     */
    onDelete() {
      // this.isLoadingTable = true
      this.deleteQuotation(this.deleteData.id)
        .then((res) => {
          this.successAlert()
          // this.isLoadingTable = false;
          this.$refs.vuetable.refresh()
        })
        .catch((err) => {
          this.errorAlert()
          console.log(err.response.message)

          // this.isLoadingTable = false;
        })
    },
    getUser() {
      try {
        this.getListUser()
          .then((res) => {
            console.log(res, 'res user')
            // this.user_id = res.result[1].id
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    getMarketingByBranchData1() {
      try {
        let strBranch = ''
        this.branchData1.forEach((data, idx) => {
          if (idx === 0) {
            strBranch += 'id_branch[]=' + data.id_branch
          } else {
            strBranch += '&id_branch[]=' + data.id_branch
          }
        })
        this.getListMarketingByBranchData1(strBranch)
          .then((res) => {
            console.log(res, 'res user by branch')
            // this.user_id = res.result[1].id
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    getMarketingByBranchData2() {
      try {
        let strBranch = ''
        this.branchData2.forEach((data, idx) => {
          if (idx === 0) {
            strBranch += 'id_branch[]=' + data.id_branch
          } else {
            strBranch += '&id_branch[]=' + data.id_branch
          }
        })
        this.getListMarketingByBranchData2(strBranch)
          .then((res) => {
            console.log(res, 'res user by branch')
            // this.user_id = res.result[1].id
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    getMarketingByBranchData(permission) {
      try {
        let permissionName = '&permissionName[] =' + permission
        console.log(permissionName,'permissionName');
        this.getListMarketingByPermissions(permissionName)
          .then((res) => {
            // console.log(res, 'test return permission')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    getMarketingByPermission(){
      for (
        let i = 0;
        i < localStorage.getItem('userPermissions').length;
        i++
      ) {
        let enable = 'BEST_CUSTOMER_ENABLE'
        let view = 'BEST_CUSTOMER_VIEW'
        let user = 'BEST_CUSTOMER_USERS'
        let parseUserPermission = localStorage.getItem('userPermissions')
        if ( 
          parseUserPermission.includes(enable) && parseUserPermission.includes(view) && parseUserPermission.includes(user)
        ) {
            this.getMarketingByBranchData(view);
            break
        }
            else if(parseUserPermission.includes(enable) && parseUserPermission.includes(view)){
              this.getMarketingByBranchData(view);
              break
            }
          else if(parseUserPermission.includes(user)){
              this.getMarketingByBranchData(user);
              break
            }
          else if(parseUserPermission.includes(enable)){
            this.getMarketingByBranchData(enable);
            break
          }
      }
    },
    formatDate(type) {
      let tempStartDateData1 = ''
      let tempEndDateData1 = ''

      let tempStartDateData2 = ''
      let tempEndDateData2 = ''

      // console.log(this.monthData1.toString())
      // console.log(new Date(this.yearData2.toString()).getFullYear())

      if (this.rangeData1 === 'customData1') {
        tempStartDateData1 = this.startDateData1
        tempEndDateData1 = this.endDateData1
      } else if (this.rangeData1 === 'monthlyData1') {
        if (this.yearData1 === null) {
          tempStartDateData1 = null
          tempEndDateData1 = null
        } else {
          tempStartDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            parseInt(this.monthData1.toString()) - 1,
            1
          )
          tempEndDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            parseInt(this.monthData1.toString()) - 1,
            1
          )

          console.log(tempStartDateData1)
          console.log(tempEndDateData1)
        }
      } else if (this.rangeData1 === 'yearlyData1') {
        if (this.yearData1 === null) {
          tempStartDateData1 = null
          tempEndDateData1 = null
        } else {
          tempStartDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            0,
            1
          )
          tempEndDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            11,
            new Date(
              new Date(this.yearData1.toString()).getFullYear(),
              12,
              0
            ).getDate()
          )
        }
      }

      if (this.rangeData2 === 'customData2') {
        tempStartDateData2 = this.startDateData2
        tempEndDateData2 = this.endDateData2
      } else if (this.rangeData2 === 'monthlyData2') {
        if (this.yearData2 === null) {
          tempStartDateData2 = null
          tempEndDateData2 = null
        } else {
          tempStartDateData2 = new Date(
            new Date(this.yearData2.toString()).getFullYear(),
            parseInt(this.monthData2.toString()) - 1,
            1
          )
          tempEndDateData2 = new Date(
            new Date(this.yearData2.toString()).getFullYear(),
            parseInt(this.monthData2.toString()) - 1,
            1
          )
        }
      } else if (this.rangeData2 === 'yearlyData2') {
        if (this.yearData2 === null) {
          tempStartDateData2 = null
          tempEndDateData2 = null
        } else {
          tempStartDateData2 = new Date(
            new Date(this.yearData2.toString()).getFullYear(),
            0,
            1
          )
          tempEndDateData2 = new Date(
            new Date(this.yearData2.toString()).getFullYear(),
            1,
            new Date(
              new Date(this.yearData2.toString()).getFullYear(),
              11,
              0
            ).getDate()
          )
        }
      }
      if (type === 'Data1') {
        if (tempStartDateData1 === null || tempEndDateData1 === null) {
          return ''
        }
        return (
          this.monthNames[tempStartDateData1.getMonth()] +
          ' ' +
          tempStartDateData1.getFullYear() +
          ' - ' +
          this.monthNames[tempEndDateData1.getMonth()] +
          ' ' +
          tempEndDateData1.getFullYear()
        )
      } else {
        if (tempStartDateData2 === null || tempEndDateData2 === null) {
          return ''
        }
        return (
          this.monthNames[tempStartDateData2.getMonth()] +
          ' ' +
          tempStartDateData2.getFullYear() +
          ' - ' +
          this.monthNames[tempEndDateData2.getMonth()] +
          ' ' +
          tempEndDateData2.getFullYear()
        )
      }
    },
    get_branch() {
      this.getListUserBranch()
        .then((res) => {
          // location.reload()
          console.log(this.listUserBranch)
        })
        .catch((err) => {
          console.log(err)
          this.errorAlert()
        })
    },
    getMarketingData1() {
      this.getMarketingByBranchData1()
    },
    getMarketingData2() {
      this.getMarketingByBranchData2()
    },
    fecthData() {
      console.log('OK')
    },
    filterData() {},
    exportData() {
      let tempStartDateData1 = ''
      let tempEndDateData1 = ''

      let tempStartDateData2 = ''
      let tempEndDateData2 = ''

      if (this.rangeData1 === 'customData1') {
        // tempStartDateData1 = this.startDateData1
        // tempEndDateData1 = this.endDateData1
        tempStartDateData1 =
          new Date(this.startDateData1).getFullYear() +
          '/' +
          (new Date(this.startDateData1).getMonth() + 1) +
          '/' +
          new Date(this.startDateData1).getDate()
        tempEndDateData1 =
          new Date(this.endDateData1).getFullYear() +
          '/' +
          (new Date(this.endDateData1).getMonth() + 1) +
          '/' +
          new Date(this.endDateData1).getDate()
      } else if (this.rangeData1 === 'monthlyData1') {
        if (this.yearData1 === null) {
          tempStartDateData1 = null
          tempEndDateData1 = null
        } else {
          tempStartDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            parseInt(this.monthData1.toString()) - 1,
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            parseInt(this.monthData1.toString()),
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData1 === 'yearlyData1') {
        if (this.yearData1 === null) {
          tempStartDateData1 = null
          tempEndDateData1 = null
        } else {
          tempStartDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear(),
            0,
            2
          )
            .toISOString()
            .split('T')[0]

          tempEndDateData1 = new Date(
            new Date(this.yearData1.toString()).getFullYear() + 1,
            0,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      }

      if (this.rangeData2 === 'customData2') {
        // tempStartDateData2 = this.startDateData2
        // tempEndDateData2 = this.endDateData2

        tempStartDateData2 =
          new Date(this.startDateData2).getFullYear() +
          '/' +
          (new Date(this.startDateData2).getMonth() + 1) +
          '/' +
          new Date(this.startDateData2).getDate()
        tempEndDateData2 =
          new Date(this.endDateData2).getFullYear() +
          '/' +
          (new Date(this.endDateData2).getMonth() + 1) +
          '/' +
          new Date(this.endDateData2).getDate()
      } else if (this.rangeData2 === 'monthlyData2') {
        tempStartDateData2 = new Date(
          new Date(this.yearData2.toString()).getFullYear(),
          parseInt(this.monthData2.toString()) - 1,
          2
        )
          .toISOString()
          .split('T')[0]
        tempEndDateData2 = new Date(
          new Date(this.yearData2.toString()).getFullYear(),
          parseInt(this.monthData2.toString()),
          1
        )
          .toISOString()
          .split('T')[0]
      } else if (this.rangeData2 === 'yearlyData2') {
        if (this.yearData2 === null) {
          tempStartDateData2 = null
          tempEndDateData2 = null
        } else {
          tempStartDateData2 = new Date(
            new Date(this.yearData2.toString()).getFullYear(),
            0,
            2
          )
            .toISOString()
            .split('T')[0]

          tempEndDateData1 = new Date(
            new Date(this.yearData2.toString()).getFullYear() + 1,
            0,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      }

      let strBranch1 = ''
      this.branchData1.forEach((data, idx) => {
        if (idx === 0) {
          strBranch1 += 'id_branch_data1[]=' + data.id_branch
        } else {
          strBranch1 += '&id_branch_data1[]=' + data.id_branch
        }
      })

      let strMarketing1 = ''
      this.marketingData1.forEach((data, idx) => {
        if (idx === 0) {
          strMarketing1 += 'id_marketing_data1[]=' + data.id_user
        } else {
          strMarketing1 += '&id_marketing_data1[]=' + data.id_user
        }
      })

      // this.moreParamsData1 = {
      //  id_branch: strBranch1,
      //  status: this.statusData1,
      //  id_marketing: strMarketing1,
      //  startDate: tempStartDateData1,
      //  endDate: tempEndDateData1,
      //  topCustomer: this.topCustomerData1,
      // }

      let strBranch2 = ''
      this.branchData2.forEach((data, idx) => {
        if (idx === 0) {
          strBranch2 += 'id_branch_data2[]=' + data.id_branch
        } else {
          strBranch2 += '&id_branch_data2[]=' + data.id_branch
        }
      })

      let strMarketing2 = ''
      this.marketingData2.forEach((data, idx) => {
        if (idx === 0) {
          strMarketing2 += 'id_marketing_data2[]=' + data.id_user
        } else {
          strMarketing2 += '&id_marketing_data2[]=' + data.id_user
        }
      })

      // this.moreParamsData2 = {
      //  id_branch: strBranch2,
      //  status: this.statusData2,
      //  id_marketing: strMarketing2,
      //  startDate: tempStartDateData2,
      //  endDate: tempEndDateData2,
      //  topCustomer: this.topCustomerData2,
      // }
      var company = localStorage.getItem('company')
      var idCompany = localStorage.getItem('id_company')

      if (this.compareData) {
        // console.log(`http://localhost:8000/generate-excel-best-customer?status_data1=${this.statusData1}&${strMarketing1}&startDate_data1=${tempStartDateData1.toISOString()}&endDate_data1=${tempEndDateData1.toISOString()}&topCustomer_data1=${this.topCustomerData1}&${strBranch1}&isCompare=true&status_data2=${this.statusData2}&${strMarketing2}&startDate_data2=${tempStartDateData2.toISOString()}&endDate_data2=${tempEndDateData2.toISOString()}&topCustomer_data2=${this.topCustomerData2}&${strBranch2}`)
        window.location.href = `${
          this.$store.state.rootApi
        }/testing-pdf?status_data1=${
          this.statusData1
        }&${strMarketing1}&startDate_data1=${this.convertDate(
          tempStartDateData1
        )}&endDate_data1=${this.convertDate(
          tempEndDateData1
        )}&topCustomer_data1=${
          this.topCustomerData1
        }&${strBranch1}&isCompare=true&status_data2=${
          this.statusData2
        }&${strMarketing2}&startDate_data2=${this.convertDate(
          tempStartDateData2
        )}&endDate_data2=${this.convertDate(
          tempEndDateData2
        )}&topCustomer_data2=${
          this.topCustomerData2
        }&${strBranch2}&company=${company}&id_company=${idCompany}`
      } else {
        // console.log(`http://localhost:8000/generate-excel-best-customer?status_data1=${this.statusData1}&${strMarketing1}&startDate_data1=${tempStartDateData1.toISOString()}&endDate_data1=${tempEndDateData1.toISOString()}&topCustomer_data1=${this.topCustomerData1}&${strBranch1}`)
        window.location.href = `${
          this.$store.state.rootApi
        }/testing-pdf?status_data1=${
          this.statusData1
        }&${strMarketing1}&startDate_data1=${this.convertDate(
          tempStartDateData1
        )}&endDate_data1=${this.convertDate(
          tempEndDateData1
        )}&topCustomer_data1=${
          this.topCustomerData1
        }&${strBranch1}&company=${company}&id_company=${idCompany}`
      }
    },
    CheckCompareData() {
      this.compareData = !this.compareData
    },
    convertDate(str) {
      let date = new Date(str)
      let mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      let day = ('0' + date.getDate()).slice(-2)

      return [date.getFullYear(), mnth, day].join('-')
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>
